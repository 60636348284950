import { usePdfMaker } from "@/utils/pdf/pdf";
import type {
  Content,
  ContentImage,
  ContentText,
  TDocumentDefinitions,
} from "pdfmake/interfaces";
import type { Participant } from "@/components/seminar/Participant/types";
import type { ParticularSeminar } from "@/components/seminar/SeminarTable/types";
import { formatDate } from "date-formatter";
import { SeminarType } from "@/api/graphql/codegen/graphql";

export const useGenerateCertificates = () => {
  const { generatePdf } = usePdfMaker();

  const generateCertificates = (seminar: ParticularSeminar) => {
    const participants = seminar.participants;
    if (!participants || participants.length === 0) return;

    const createCertificateForParticipant = (
      participant: Participant,
      identifier: number,
      addPageBreak = false
    ) => {
      const certificate: Content = [
        {
          alignment: "center",
          fontSize: 9,
          columns: [
            [
              ...(seminar.type === SeminarType.Idz
                ? [
                    {
                      image: "euMsmtLogo",
                      alignment: "center",
                      width: 5581 / 18,
                      height: 795 / 18,
                      margin: [0, 0, 0, 15],
                    } as ContentImage,
                  ]
                : []),
              {
                text: "VYSOČINA EDUCATION",
                style: "subheader",
              },
              "školské zařízení pro další vzdělávání pedagogických pracovníků",
              "příspěvková organizace IČO: 751 40 349",
              {
                text: "akreditovaná pod č.j.: 1122/2021-1",
                style: "subheader",
              },
              {
                text: "Ke Skalce 5907/47, 586 01 Jihlava",
                style: "subheader",
              },
            ],
            ...(seminar.type === SeminarType.Default
              ? [
                  {
                    image: "vysEduLogo",
                    width: 100,
                    height: 100,
                    margin: [5, -10, 5, 0],
                  } as ContentImage,
                ]
              : []),
          ],
        },
        {
          table: {
            widths: ["*"],
            body: [[" "], [" "]],
          } as any,
          layout: {
            hLineWidth: function (i: any, node: any) {
              return i === 0 || i === node.table.body.length ? 0 : 1;
            },
            vLineWidth: function () {
              return 0;
            },
          },
          marginTop: -15,
        },
        {
          text: `${seminar.identifier} - ${identifier}`,
          alignment: "left",
          marginTop: -15,
        },
        {
          text: "OSVĚDČENÍ",
          style: "title",
          margin: seminar.type === SeminarType.Idz ? 5 : 20,
        },
        {
          text: participant.name,
          style: "subtitle",
        },
        {
          text: `narozen(a) ${formatDate(participant.dateOfBirth)}`,
          style: "default",
        },
        {
          text: `absolvoval(a) seminář`,
          style: "default",
        },
        {
          text: seminar.name,
          style: "subtitle",
          marginBottom: 40,
        },
        {
          text: seminar.accreditation
            ? `Vzdělávací program byl akreditován MŠMT ČR v rámci dalšího vzdělávání pedagogických pracovníků pod č. j. ${seminar.accreditation}`
            : "",
          marginBottom: seminar.type === SeminarType.Idz ? 10 : 40,
          style: {
            alignment: "center",
          },
        },
        ...(seminar.type === SeminarType.Idz
          ? [
              {
                text:
                  "Implementace dlouhodobého záměru Kraje Vysočina.\n" +
                  "CZ.02.02.XX/00/23_018/0010144 \n" +
                  "Tento projekt je spolufinancován Evropskou unií.",
                marginBottom: 10,
                style: {
                  alignment: "center",
                },
              } as ContentText,
            ]
          : []),
        {
          stack: [
            `datum konání: ${formatDate(seminar.date)}${
              seminar.endDate ? ` - ${formatDate(seminar.endDate)}` : ""
            }`,
            `místo: ${seminar.city}`,
            `počet hodin: ${seminar.duration}`,
            seminar.lector ? `lektor: ${seminar.lector}` : "",
          ],
          alignment: "center",
          margin:
            seminar.type === SeminarType.Default
              ? [0, 50, 0, 50]
              : [0, 30, 0, 30],
        },
        {
          text: `Způsob zakončení programu: Účastníci vyplnili evaluační dotazník a obdrželi osvědčení.`,
          alignment: "center",
        },
        {
          columns: [
            {
              stack: [
                seminar.city,
                `${formatDate(seminar.endDate ?? seminar.date)}`,
              ],
              alignment: "left",
            },
            {
              text: "razítko",
              alignment: "center",
              marginRight: 40,
            },
            {
              stack: [
                `v z. ..........................................`,
                "Mgr. Roman Křivánek",
                "Ředitel",
              ],
              alignment: "center",
            },
          ],
          alignment: "justify",
          absolutePosition: { x: 80, y: 730 },
        },
        {
          ...(addPageBreak
            ? {
                text: "",
                pageBreak: "after",
              }
            : { text: "" }),
        },
      ];

      return certificate;
    };

    const certificates = participants.map((participant, index) =>
      createCertificateForParticipant(
        participant,
        index + 1,
        index !== participants.length - 1
      )
    );

    const docDefinition: TDocumentDefinitions = {
      content: [...certificates],
      images: {
        vysEduLogo:
          "https://vys-edu.s3.eu-central-1.amazonaws.com/vys-edu-logo.jpeg",
        euMsmtLogo: "https://vys-edu.s3.eu-central-1.amazonaws.com/eu-msmt.png",
      },
      styles: {
        default: {
          alignment: "center",
        },
        subheader: {
          fontSize: 13,
          bold: true,
        },
        title: {
          fontSize: 36,
          alignment: "center",
          bold: true,
          marginTop: 30,
          marginBottom: 30,
        },
        subtitle: {
          fontSize: 20,
          alignment: "center",
          bold: true,
          marginTop: 20,
          marginBottom: 20,
        },
      },
      info: {
        title: "Osvědčení",
        author: "Vysočina Education",
        subject: "Osvědčení",
        keywords: "Osvědčení",
      },
    };

    generatePdf(docDefinition, `Osvědčení - ${formatDate(seminar.date)}`);
  };

  return {
    generateCertificates,
  };
};
