import { createApp, h } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import { Quasar } from "quasar";
import { quasarOptions } from "@/quasar-user-options";
import "@quasar/extras/material-icons/material-icons.css";
import "quasar/src/css/index.sass";
import { createAuth0 } from "@auth0/auth0-vue";
import * as Sentry from "@sentry/vue";
import { VueQueryPlugin } from "@tanstack/vue-query";

const app = createApp({
  render: () => h(App),
});

if (!import.meta.env.DEV) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}

app.use(Quasar, quasarOptions);
app.use(createPinia());
app.use(router);

const auth0 = createAuth0({
  domain: import.meta.env.VITE_AUTH_DOMAIN,
  client_id: import.meta.env.VITE_AUTH_CLIENT_ID,
  redirect_uri: window.location.origin,
  cacheLocation: "localstorage",
  useRefreshTokens: true,
  audience: import.meta.env.VITE_AUTH_AUDIENCE,
});

app.use(auth0);
app.use(VueQueryPlugin);
app.mount("#app");
