import { graphql } from "@/api/graphql/codegen";

export const GetSeminarsQueryDocument = graphql(/* GraphQL */ `
  query GetSeminars($seminarsInput: GetSeminarsInput!) {
    seminars(seminarsInput: $seminarsInput) {
      id
      identifier
      name
      date
      endDate
      lector
      accreditation
      city
      duration
      price
      type
      capacity
      participants {
        id
        name
        dateOfBirth
        paymentMethod
        email
        phone
        school
        createdAt
        updatedAt
      }
    }
  }
`);
